<template>
  <span class="asset-icon">
    <span v-if="asset == 'BNB'">
      <img src="../assets/img/icon_bnb.png" alt />
    </span>
    <span v-else-if="asset == 'BTC'">
      <img src="../assets/img/icon_btc.png" alt />
    </span>
    <span v-else-if="asset == 'DOGE'">
      <img src="../assets/img/icon_doge.png" alt />
    </span>
    <span v-else-if="asset == 'ETH'">
      <img src="../assets/img/icon_eth.png" alt />
    </span>
    <span v-else-if="asset == 'FTT'">
      <img src="../assets/img/icon_ftt.png" alt />
    </span>
    <span v-else-if="asset == 'MER'">
      <img src="../assets/img/icon_mer.png" alt />
    </span>
    <span v-else-if="asset == 'MNGO'">
      <img src="../assets/img/icon_mngo.png" alt />
    </span>
    <span v-else-if="asset == 'PAI'">
      <img src="../assets/img/icon_pai.png" alt />
    </span>
    <span v-else-if="asset == 'RAY'">
      <img src="../assets/img/icon_ray.png" alt />
    </span>
    <span v-else-if="asset == 'SOL'">
      <img src="../assets/img/icon_sol.png" alt />
    </span>
    <span v-else-if="asset == 'SRM'">
      <img src="../assets/img/icon_srm.png" alt />
    </span>
    <span v-else-if="asset == 'SYP'">
      <img src="../assets/img/icon_syp.png" alt />
    </span>
    <span v-else-if="asset == 'USDC'">
      <img src="../assets/img/icon_usdc.png" alt />
    </span>
    <span v-else-if="asset == 'FIDA'">
      <img src="../assets/img/icon_fida.png" alt />
    </span>
    <span v-else-if="asset == 'USDT'">
      <img src="../assets/img/icon_usdt.png" alt />
    </span>
    <span v-else-if="asset == 'WOO'">
      <img src="https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E5rk3nmgLUuKUiS94gg4bpWwWwyjCMtddsAXkTFLtHEy/logo.png" alt />
    </span>
  </span>
</template>

<script>
export default {
  name: "Asset Icon",
  props: {
    asset: String
  }
};
</script>

<style lang="less" scoped>
.asset-icon {
  > span {
    display: inline-block;
    width: 30px;
    padding-top: 30px;
    position: relative;
    > img {
      position: absolute;
      top: 0%;
      height: 100%;
      width: 100%;
    }
  }
}
</style>

<template>
  <div class="chart-box">
    <div
      class="chart"
      :id="chartId"
      style="width: 160px; height: 47px"
    ></div>
  </div>
</template>

<script>
export default {
  props: ["chartId", "dateList", "dataList", "max", "min"],
  data() {
    return {
      myChart: {}
    };
  },
  methods: {
    chartInit(date, data) {
      this.myChart.setOption({
        backgruondColor: "#14151a",
        grid: {
          bottom: 30,
          left: 40,
          top: 30,
          right: 30
        },
        // tooltip: {
        //     trigger: 'axis'
        // },
        xAxis: {
          type: "category",
          data: data,
          boundaryGap: false,
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#25242b"
            }
          },
          min: this.min,
          max: this.max
        },
        series: [
          {
            data: data,
            type: "line",
            itemStyle: {
              opacity: 0,
              color: "#13eff9"
            }
            // areaStyle: {
            // color: {
            //     type: 'linear',
            //     x: 0,
            //     y: 0,
            //     x2: 0,
            //     y2: 1,
            //     colorStops: [{ offset: 0, color: 'rgba(54,55,135,0.7)' }, { offset: 1, color: 'rgba(54,55,135,0.2)' }],
            // }
            // // color: 'rgba(54,55,135,0.7)'
            // }
          }
        ]
      });
    }
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.chartId));
  },
  watch: {
    dataList: {
      deep: true,
      handler(newValue, oldValue) {
        this.chartInit(this.dateList, this.dataList);
      }
    }
  }
};
</script>

<style>
.chart-box {
  margin-left: -50px;
}
</style>
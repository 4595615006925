import {
  parseMappingData,
  parsePriceData,
  parseProductData,
} from "@pythnetwork/client";
import { PublicKey } from "@solana/web3.js";

const nullKey = "11111111111111111111111111111111";
const nullAcc = new PublicKey(nullKey);
const ChainLinkFeedProgramId = "cjg3oHmg9uuPsP8D6g29NWvhySJkdYdAo9D25PRbKXJ";
const ChainLinkStoreProgramId = "HEvSKofvBgfaexv23kMabbYqxasxU3mQ4ibBMEmJWHny";
import * as BufferLayout from "@solana/buffer-layout";

// pyth
// USDC must be the first oracle
// mainnet 0209
export const oraclePublicKey = [
  {
    symbol: "USDC",
    publicKey: "8GWTTbNiXdmyZREXbjsZBmCRuzdPrW55dnZGDkTRjWvb",
    price: "Gnt27xtC473ZT2Mw5u8wZ68Z3gULkSTb5DuxJy7eJotD",
  },
  {
    symbol: "BNB",
    publicKey: "4ANvG59u4YToJnVi2CkZbbfTE9mWHpyNbMmm69R43E3q",
    price: "4CkQJBxhU8EZ2UjhigbtdaPbpTe6mqf811fipYBFbSYN",
  },
  {
    symbol: "BTC",
    publicKey: "4aDoSXJ5o3AuvL7QFeR6h44jALQfTmUUCTVGDD6aoJTM",
    price: "GVXRSBjFk6e6J3NbVPXohDJetcTjaeeuykUpbQF8UoMU",
  },
  {
    symbol: "DOGE",
    publicKey: "GeSpWQucSvWhaXVvC2RJihpne9dCdL25pn46BTiDLyYs",
    price: "FsSM3s38PX9K7Dn6eGzuE29S2Dsk1Sss1baytTQdCaQj",
  },
  {
    symbol: "ETH",
    publicKey: "EMkxjGC1CQ7JLiutDbfYb7UKb3zm9SJcUmr1YicBsdpZ",
    price: "JBu1AL4obBcCMqKBBxhpWCNUt136ijcuMZLFvTP7iWdB",
  },
  {
    symbol: "FTT",
    publicKey: "E7ymZgACaSd1fYLf2V7WkAmFkpRiWfAjm49R7bceny9K",
    price: "8JPJJkmDScpcNmBRKGZuPuG2GYAveQgP3t5gFuMymwvF",
  },
  {
    symbol: "FIDA",
    publicKey: "HyEB4Goiv7QyfFStaBn49JqQzSTV1ybtVikwsMLH1f2M",
    price: "ETp9eKXVv1dWwHSpsXRUuXHmw24PwRkttCGVgpZEY9zF",
  },
  {
    symbol: "LUNA",
    publicKey: "2Lg3b2UdD4hzrxHpcwhgShuUdccTKFoo2doAUZawEdPH",
    price: "5bmWuR1dgP4avtGYMNKLuxumZTVKGgoN2BCMXWDNL9nY",
  },
  {
    symbol: "MER",
    publicKey: "6QAiy88ry7A5RKXDrLc8RCtwkeA8JU1JVqCNVugXa7R2",
    price: "G4AQpTYKH1Fmg38VpFQbv6uKYQMpRhJzNPALhp7hqdrs",
  },
  {
    symbol: "MNGO",
    publicKey: "Cgua36MoUVXAPhKXAUsd5LsfM2s4Bj9jsMASm5rs8JZy",
    price: "79wm3jjcPr6RaNQ4DGvP5KxG1mNd3gEBsg6FsNVFezK4",
  },
  // { symbol: 'PAI', publicKey: "6NpdXrQEpmDZ3jZKmM2rhdmkd3H6QAk23j2x8bkXcHKA", price: "5SSkXsEKQepHHAewytPVwdej4epN1nxgLVM84L4KXgy7" },
  {
    symbol: "RAY",
    publicKey: "EjtaES3pbMCaNrVFtZ5sT36WPXAnARZxCFsoEr2hsUKJ",
    price: "AnLf8tVYCM816gmBjiy8n53eXKKEDydT5piYjjQDPgTB",
  },
  {
    symbol: "SOL",
    publicKey: "ALP8SdU9oARYVLgLR7LrqMNCYBnhtnQz1cj6bwgwQmgj",
    price: "H6ARHf6YXhGYeQfUzQNGk6rDNnLBQKrenN712K4AQJEG",
  },
  {
    symbol: "SPY",
    publicKey: "CUfAvfiqyTickCSA32bExQeP6AkRrWV6FqkHx6S8Jyua",
    price: "2k1qZ9ZMNUNmpGghq6ZQRj7z2d2ATNnzzYugVhiTDCPn",
  },
  {
    symbol: "SRM",
    publicKey: "6T1eJbKWyhZXEjmuBej9gPk3SRTagzrEQovCSwAWD57P",
    price: "3NBReDRTLKMQEKiLD5tGcx4kXbTf88b7f2xLS9UuGjym",
  },
  {
    symbol: "XAU",
    publicKey: "9HaWvL7bACK8giZEvAbsaMXDo9Dudu18Ph921NKGuU7i",
    price: "8y3WWjvmSmVGWVKH1rCA7VTRmuU7QbJ9axafSsBX5FcD",
  },
  { symbol: "SYP", publicKey: nullKey, price: nullKey },
];

// chainlink
// USDC must be the first oracle
// mainnet 0607
export const oraclePublicKeyC = [
  {
    symbol: "USDC",
    publicKey: "7CLo1BY41BHAVnEs57kzYMnWXyBJrVEBPpZyQyPo2p1G",
    decimals: 8,
  },
  {
    symbol: "BTC",
    publicKey: "CGmWwBNsTRDENT5gmVZzRu38GnNnMm1K5C3sFiUUyYQX",
    decimals: 8,
  },
  {
    symbol: "ETH",
    publicKey: "5WyTBrEgvkAXjTdYTLY9PVrztjmz4edP5W9wks9KPFg5",
    decimals: 8,
  },
  {
    symbol: "SOL",
    publicKey: "CcPVS9bqyXbD9cLnTbhhHazLsrua8QMFUHTutPtjyDzq",
    decimals: 8,
  },
  {
    symbol: "LINK",
    publicKey: "EH32v4UHcwH6S7gLTRvEBEyCTJrVbhRiJE7QEGoqd4NU",
    decimals: 8,
  },
  {
    symbol: "SRM",
    publicKey: "2v4qnkskmnXjeYRQkdFTPLPsU42vhG1tAKbRvw7Vqjij",
    decimals: 8,
  },
  {
    symbol: "USDT",
    publicKey: "76npM99oWkDXdepEJLXc3chmya2n1tEZzqfU2n67nywS",
    decimals: 8,
  },
];

const NewTransmissionLayout = BufferLayout.struct([
  BufferLayout.ns64("unknow"),
  BufferLayout.nu64("timestamp"),
  BufferLayout.ns64("answerL"),
  BufferLayout.ns64("answerH"),
]);

export async function getTokenPrice(connection, pubkey) {
  let accountInfo = await connection.getAccountInfo(pubkey);
  let { product, priceAccountKey } = parseProductData(accountInfo.data);
  let dataResult = await connection.getAccountInfo(priceAccountKey);
  let { price, confidence, exponent } = parsePriceData(dataResult.data);
  return [price, exponent];
}

export async function getTokenPriceOnly(connection, pubkey) {
  let dataResult = await connection.getAccountInfo(pubkey);
  let { price, confidence, exponent } = parsePriceData(dataResult.data);
  return [price, exponent];
}

export async function getTokenPrice2(connection, tokenName) {
  // syp has no oracle, its price is fixed.
  if (tokenName == "SYP") {
    return {
      code: 1,
      token: tokenName,
      data: 0.05,
      msg: "get oracle price ok",
    };
  } else {
    let priceKey = getOraclePriceKey(tokenName);
    if (priceKey) {
      let temp = await connection.getAccountInfo(new PublicKey(priceKey));
      if (!temp) {
        return {
          code: 0,
          token: tokenName,
          data: 1.0,
          msg: "get oracle error",
        };
      }
      let { price, exponent } = parsePriceData(temp.data);
      if (price) {
        return {
          code: 1,
          token: tokenName,
          data: price,
          decimals: exponent,
          msg: "get oracle price ok",
        };
      } else {
        return {
          code: 0,
          token: tokenName,
          data: 1.0,
          msg: "get oracle error",
        };
      }
    } else {
      return { code: 0, token: tokenName, data: 1.0, msg: "null oracle" };
    }
  }
}

export function getOraclePriceKey(tokenName) {
  // syp has no oracle
  if (tokenName == "SYP") {
    return nullKey;
  }
  let temp = oraclePublicKey.find((e) => {
    return e.symbol == tokenName;
  });
  if (!temp) {
    console.log("can not find oracle", tokenName);
    return nullKey;
  }
  return temp.price;
}

export async function getChainLinkPrice(tokenName) {
  let oracle = oraclePublicKeyC.find((e) => {
    return e.symbol === tokenName;
  });
  if (!oracle) return 0;
  let data = await getChainLinkData(oracle.publicKey);
  if (!data) return 0;
  // decode submit
  let dataBuffer = bs58.decode(data);
  let newTransmission = NewTransmissionLayout.decode(dataBuffer);
  let answer = newTransmission.answerL + newTransmission.answerH * 2 ** 64;
  return answer / 10 ** oracle.decimals;
}

export async function getChainLinkData(key) {
  let signature;
  {
    let res = await getSignatureInfo(key);
    if (!res) return;
    signature = res.signature;
  }
  let data;
  {
    let res = await connection.getTransaction(signature, {
      commitment: "confirmed",
    });
    if (!res) return;
    let accounts = res.transaction.message.accountKeys.map((e) => {
      return e.toBase58();
    });
    let innerInstructions = res.meta.innerInstructions;
    let programIds = innerInstructions.map((e) => {
      return accounts[e.instructions[0].programIdIndex];
    });
    let index = programIds.indexOf(ChainLinkStoreProgramId);
    if (index == -1) {
      data = await getChainLinkData(key);
    } else {
      data = innerInstructions[index].instructions[0].data;
    }
  }
  return data;
}

export async function getSignatureInfo(key) {
  let res = await connection.getConfirmedSignaturesForAddress2(
    new PublicKey(key),
    { limit: 1 },
    "confirmed"
  );
  if (!res.length) return;
  let info = res[0];
  if (info.err) info = await getSignatureInfo(key);
  return info;
}

export const months = {
  JAN: 0,
  FEB: 1,
  MAR: 2,
  APR: 3,
  MAY: 4,
  JUN: 5,
  JUL: 6,
  AUG: 7,
  SEP: 8,
  OCT: 9,
  NOV: 10,
  DEC: 11,
};
export const monthsRev = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export const TOKEN_VESTING_PROGRAM_ID_DEV =
  "EciLDZ9Vvxy28KKYoyYzFTcGeMKo4z2Kw4gf1jHEeADc";

<template>
  <div class="ranking view">
    <el-table
      :data="tableData"
      :cell-style="setCellStyle"
      @row-click="gotoSypool"
      @sort-change="sortChange"
    >
      <el-table-column width="140" prop="assetPool" label="SAP" />
      <el-table-column width="100" prop="manager" label="Manager" />
      <el-table-column width="140" prop="topAssets" label="Top Assets">
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.topAssets"
            :key="index"
            class="coin-icon"
          >
            <AssetIcon :asset="item.name.toUpperCase()" />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        width="140"
        prop="marketCap"
        label="Market Cap"
        sortable="custom"
        :sort-orders="['ascending', 'descending']"
      />
      <el-table-column width="80" prop="risk" label="Risk" sortable="custom" />
      <el-table-column width="80" prop="shares" label="Shares" />
      <el-table-column
        width="80"
        prop="nav"
        label="NAV"
        sortable="custom"
        :sort-orders="['ascending', 'descending']"
      />
      <el-table-column
        width="160"
        prop="sinceInception"
        label="Since Inception"
        sortable="custom"
        :sort-orders="['ascending', 'descending']"
      />
      <el-table-column
        width="120"
        prop="apy24"
        label="24H perf."
        sortable="custom"
        :sort-orders="['ascending', 'descending']"
      />
      <el-table-column label="30D perf.">
        <template slot-scope="scope">
          <Chart
            :chartId="scope.row.myChartId"
            :dateList="scope.row.dateList"
            :dataList="scope.row.dataList"
            :max="scope.row.chartMax"
            :min="scope.row.chartMin"
          />
        </template>
      </el-table-column>
      <template slot="empty">
        <div class="noData">No available Data</div>
      </template>
    </el-table>
  </div>
</template>

<script>
import { Connection } from "@solana/web3.js";
import { getAssetValue, getAssetValueSY } from "../assets/js/SYcalculate";
import Chart from "../components/chart.vue";
import AssetIcon from "../components/assetIcon.vue";
import { rpcUrl } from "../assets/js/index";
import { SY_LEN } from "../assets/js/sap/sap";
import { rpcConfig } from "../assets/js";

export default {
  name: "Pools",
  data() {
    return {
      tableData: [],
      mintTypes: [],
    };
  },
  components: { Chart, AssetIcon },
  async mounted() {
    this.connection = new Connection(rpcUrl, rpcConfig);
    let result = await this.$axios.post("/getFundList", { net: 0 });
    let tableData = [];
    let products = result.data.data;
    // init table
    let syOffset = 0;
    for (let i = 0; i < products.length; i++) {
      if (products[i].type == 0) {
        let product = {
          assetPool: products[i].name,
          manager: "Sypool",
          topAssets: "",
          sinceInception: "0%",
          apy: "0%",
          apy30: "0%",
          apy24: "0%",
          marketCap: "$0",
          risk: "Low",
          shares: "0",
          pubkey: products[i].pubkey,
          mint: products[i].mint[0],
          myChartId: "myChart" + (i + syOffset).toString(),
          dateList: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
          ],
          dataList: [],
          chartMax: 0,
          chartMin: 0,
          nav: 0,
          index: tableData.length,
        };
        tableData.push(product);
      } else if (products[i].type == 1) {
        for (let j = 1; j < 1 + SY_LEN; j++) {
          let product = {
            assetPool: products[i].name + "-R" + j,
            manager: "Sypool",
            topAssets: "",
            sinceInception: "0%",
            apy: "0%",
            apy30: "0%",
            apy24: "0%",
            marketCap: "$0",
            risk: "Low",
            shares: "0",
            pubkey: products[i].pubkey,
            mint: products[i].mint[j - 1],
            myChartId: "myChart" + (i + syOffset + j).toString(),
            dateList: [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
              "24",
              "25",
              "26",
              "27",
              "28",
              "29",
              "30",
            ],
            dataList: [],
            chartMax: 0,
            chartMin: 0,
            nav: 0,
            index: tableData.length,
          };
          tableData.push(product);
        }
        syOffset++;
      }
    }
    this.tableData = tableData;
    // get table asset data
    syOffset = 0;
    for (let i = 0; i < products.length; i++) {
      let index = i + syOffset;
      if (products[i].enableWoo) {
        let res = await this.$axios.get("/getFundWooAsset", {
          params: {
            publicKey: products[i].pubkey,
          },
        });
        if (res.data.code == 1) {
          products[i].wooAsset = res.data.data;
        } else {
          console.warn(res);
        }
      }
      if (products[i].type == 0) {
        let chartData = await this.$axios.post("/getSapHistoryData", {
          mint: products[i].mint[0],
          pubkey: products[i].pubkey,
          range: "month",
        });
        let CD = this.getChartData(chartData, products[i].init_price);
        let [maxA, percent, apy, cap, supply, onePrice, composition] =
          await getAssetValue(this.connection, products[i]);
        if (composition.length <= 6) {
          this.tableData[index].topAssets = composition;
        } else {
          this.tableData[index].topAssets = composition.slice(0, 6);
        }
        this.tableData[index].sinceInception = (percent * 100).toFixed(3) + "%";
        this.tableData[index].apy = (apy * 100).toFixed(3) + "%";
        let apy30d = Math.pow(apy / (365 / 30) + 1, 365 / 30) - 1;
        // let apy1d = (Math.pow((apy / 365 + 1), 365) - 1);
        this.tableData[index].apy30 = (apy30d * 100).toFixed(3) + "%";
        // this.tableData[index].apy24 = (apy1d * 100).toFixed(3) + '%';
        // calculate apy
        if (chartData.data.code == 1) {
          let sapPriceList = chartData.data.sapMinPrice;
          if (sapPriceList.length > 1) {
            let length = sapPriceList.length;
            let sapPrice1 = sapPriceList[length - 2].price;
            let sapPrice2 = sapPriceList[length - 1].price;
            let apy = (sapPrice2 - sapPrice1) / sapPrice1;
            if (sapPrice1 == 0) apy = 0;
            this.tableData[index].apy24 = (apy * 100).toFixed(3) + "%";
          } else {
            this.tableData[index].apy24 = (percent * 100).toFixed(3) + "%";
          }
        }
        this.tableData[index].marketCap = "$" + cap.toFixed(2);
        this.tableData[index].shares = supply.toFixed(4);
        this.tableData[index].risk = products[i].risk;
        this.tableData[index].netProfit = (cap * percent).toFixed(2);
        this.tableData[index].dataList = CD.chartShowData;
        this.tableData[index].chartMax = CD.chartMax;
        this.tableData[index].chartMin = CD.chartMin;
        if (supply != 0) {
          this.tableData[index].nav = ((cap / supply) * 0.01).toFixed(4);
        } else {
          this.tableData[index].nav = "0.0";
        }
      } else if (products[i].type == 1) {
        let results = await getAssetValueSY(
          this.connection,
          rpcUrl,
          products[i]
        );
        let index = i + syOffset;
        for (let j = 0; j < SY_LEN; j++) {
          let chartData = await this.$axios.post("/getSapHistoryData", {
            mint: products[i].mint[j],
            pubkey: products[i].pubkey,
            range: "month",
          });
          let CD = this.getChartData(chartData, products[i].init_price);
          let [
            maxA,
            percent,
            apy,
            apy30d,
            apy1d,
            cap,
            supply,
            onePrice,
            composition,
          ] = results[j];
          if (composition.length <= 6) {
            this.tableData[index + j].topAssets = composition;
          } else {
            this.tableData[index + j].topAssets = composition.slice(0, 6);
          }
          this.tableData[index + j].sinceInception =
            (percent * 100).toFixed(3) + "%";
          this.tableData[index + j].apy = (apy * 100).toFixed(3) + "%";
          this.tableData[index + j].apy30 = (apy30d * 100).toFixed(3) + "%";
          // this.tableData[index + j].apy24 = (apy1d * 100).toFixed(3) + '%';
          // calculate apy
          if (chartData.data.code == 1) {
            let sapPriceList = chartData.data.sapMinPrice;
            if (sapPriceList.length > 1) {
              let length = sapPriceList.length;
              let sapPrice1 = sapPriceList[length - 2].price;
              let sapPrice2 = sapPriceList[length - 1].price;
              let apy = (sapPrice2 - sapPrice1) / sapPrice1;
              if (sapPrice1 == 0) apy = 0;
              if (apy < -1) apy = -1;
              this.tableData[index + j].apy24 = (apy * 100).toFixed(3) + "%";
            } else {
              this.tableData[index + j].apy24 =
                (percent * 100).toFixed(3) + "%";
            }
          }
          this.tableData[index + j].marketCap = "$" + cap.toFixed(1);
          this.tableData[index + j].shares = supply.toFixed(4);
          this.tableData[index].risk = products[i].risk;
          this.tableData[index + j].netProfit = (cap * percent).toFixed(2);
          this.tableData[index + j].dataList = CD.chartShowData;
          // console.log(index, j);
          this.tableData[index + j].chartMax = CD.chartMax;
          this.tableData[index + j].chartMin = CD.chartMin;
          if (supply != 0) {
            this.tableData[index + j].nav = (onePrice * 0.01).toFixed(4);
          } else {
            this.tableData[index + j].nav = "0.0";
          }
          if (j == 0) {
            this.tableData[index + j].risk = "Low";
          }
          if (j == 1) {
            this.tableData[index + j].risk = "High";
          }
        }
        syOffset++;
      }
    }
    // console.log("&&&&tabledata",this.tableData)
  },
  methods: {
    gotoSypool(row, event, column) {
      this.$router.push(
        "/sypool?pubkey=" +
          row.pubkey +
          "&mint=" +
          row.mint +
          "&index=" +
          row.index
      );
    },
    dataSort(prop, order) {
      if (order === "ascending") {
        this.tableData.sort((a, b) => {
          // console.log("&&&&prop", prop)
          if (prop === "marketCap") {
            return (
              a[prop].slice(1, a[prop].length - 1) -
              b[prop].slice(1, b[prop].length - 1)
            );
          } else if (prop === "nav") {
            return a[prop] - b[prop];
          } else if (prop === "risk") {
            if (
              a[prop] === "Low" &&
              (b[prop] === "Medium" || b[prop] === "High")
            ) {
              return -1;
            } else if (a[prop] === "Medium" && b[prop] === "High") {
              return -1;
            } else return 1;
          }
          return (
            a[prop].slice(0, a[prop].length - 1) -
            b[prop].slice(0, b[prop].length - 1)
          );
        });
      } else if (order === "descending") {
        this.tableData.sort((a, b) => {
          if (prop === "marketCap")
            return (
              b[prop].slice(1, b[prop].length - 1) -
              a[prop].slice(1, a[prop].length - 1)
            );
          else if (prop === "nav") {
            return b[prop] - a[prop];
          } else if (prop === "risk") {
            if (
              a[prop] === "Low" &&
              (b[prop] === "Medium" || b[prop] === "High")
            ) {
              return 1;
            } else if (a[prop] === "Medium" && b[prop] === "High") {
              return 1;
            } else return -1;
          }
          return (
            b[prop].slice(0, b[prop].length - 1) -
            a[prop].slice(0, a[prop].length - 1)
          );
        });
      }
      // console.log(this.tableData)
    },
    sortChange(column, prop, order) {
      this.dataSort(column.prop, column.order);
    },
    setCellStyle(row, column, rowIndex, columnIndex) {
      let val;
      if (row.columnIndex != 5 && row.columnIndex != 6) {
        val = row.row[row.column.property];
      }
      if (typeof val != "undefined") {
        val = val.slice(0, val.length - 1);
      }
      if (val > 0) {
        return "color: #34D399";
      } else if (val < 0) {
        return "color: #F87171";
      }
    },
    sd(numbers) {
      const mean = numbers.reduce((acc, n) => acc + n) / numbers.length;
      return Math.sqrt(
        numbers.reduce((acc, n) => (n - mean) ** 2) / numbers.length
      );
    },
    getChartData(chartData, initPrice) {
      let res = { chartShowData: [], chartMax: 0, chartMin: 0 };
      if (chartData.data.sapMinPrice != undefined) {
        if (chartData.data.sapMinPrice.length > 1) {
          let data = chartData.data.sapMinPrice;
          let values = data.map((e) => {
            return e.price;
          });
          let std = this.sd(values);
          res.chartMax = Math.ceil(Math.max(...values) + 5 * std);
          res.chartMin = Math.floor(Math.min(...values) - 5 * std);
          res.chartShowData = values;
        } else if (chartData.data.sapMinPrice.length == 1) {
          let data = chartData.data.sapMinPrice;
          let values = data.map((e) => {
            return e.price;
          });
          values.unshift(initPrice);
          let std = this.sd(values);
          res.chartMax = Math.ceil(Math.max(...values) + 5 * std);
          res.chartMin = Math.floor(Math.min(...values) - 5 * std);
          res.chartShowData = values;
        }
      }
      return res;
    },
  },
};
</script>

<style lang="less" scoped>
.view {
  height: 100%;
  padding: 25px;
  background-color: #111;
  border-radius: 25px;
}

.coin-icon {
  display: inline-block;
  border-radius: 50%;
  // overflow: hidden;
  width: 30px;
  /*margin-left: -15px;*/

  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);

  &:nth-child(1) {
    left: 0px;
    z-index: 10;
  }

  &:nth-child(2) {
    left: 15px;
    z-index: 9;
  }

  &:nth-child(3) {
    left: 30px;
    z-index: 8;
  }

  &:nth-child(4) {
    left: 45px;
    z-index: 7;
  }

  &:nth-child(5) {
    left: 60px;
    z-index: 6;
  }

  &:nth-child(6) {
    left: 75px;
    z-index: 5;
  }

  &:nth-child(7) {
    left: 90px;
    z-index: 4;
  }

  &:nth-child(8) {
    left: 105px;
    z-index: 3;
  }

  &:nth-child(9) {
    left: 120px;
    z-index: 2;
  }

  &:nth-child(10) {
    left: 135px;
    z-index: 1;
  }

  &:nth-child(11) {
    left: 150px;
    z-index: 0;
  }
}
</style>
